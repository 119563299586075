import { Layout } from '@/bloks/Global/Layout';
import { ClientProviders } from '@/client/ClientProviders';
import { DefaultProps, buildGetStaticProps } from '@/server/slug';
import { getSingleStoryBySlug } from '@/server/storyblok';
import { FetchApiError } from '@/utils/fetchApi/fetchApiRaw';
import { isSlugClean } from '@/utils/string';
import React from 'react';
import { GetStaticPaths } from 'next';

const Page: React.FC<DefaultProps> = ({ ...props }) => {
	return (
		<ClientProviders providerParams={props}>
			<Layout {...props} />
		</ClientProviders>
	);
};

export default Page;

export const getStaticProps = buildGetStaticProps<DefaultProps>({
	initialize(params) {
		const pageSlug = ['agoy', ...((params.slug as string[]) || [])].join('/');

		if (!isSlugClean(pageSlug)) {
			return { notFound: true };
		}

		const storySlug = pageSlug;

		return { build: { pageSlug, storySlug } };
	},
	async build(helper) {
		const storyPromise = getSingleStoryBySlug(helper.sbParams, helper.storySlug);

		// NOTE: Temporary Redirect /agoy/any-unknown-page to /agoy
		// NOTE: It does not redirect if the slug is not clean
		if (helper.storySlug !== 'agoy') {
			try {
				await storyPromise;
			} catch (err) {
				const error = err as FetchApiError;
				if (error.status === 404) {
					return { redirect: { destination: '/agoy', permanent: false } };
				}
			}
		}

		await helper.fetchProps({});
		await helper.fetchModulesData();
		await helper.fetchBreadcrumbs();
		helper.fetchProductData();
		helper.handleServerPageConfig();
	},
});

export const getStaticPaths: GetStaticPaths = async () => {
	return { paths: [], fallback: 'blocking' };
};
